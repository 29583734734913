import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Wiki() {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const articles = {
    'understanding-mindfulness': {
      title: "Understanding Mindfulness",
      category: "Getting Started",
      content: `
        <h1 class="text-3xl font-light mb-6">Understanding Mindfulness</h1>
        <p class="mb-4">Mindfulness is the practice of maintaining a nonjudgmental state of heightened or complete awareness of one's thoughts, emotions, or experiences on a moment-to-moment basis.</p>
        
        <h2 class="text-2xl font-light mt-8 mb-4">Key Concepts</h2>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Present moment awareness</li>
          <li class="mb-2">Non-judgmental observation</li>
          <li class="mb-2">Acceptance of thoughts and feelings</li>
          <li class="mb-2">Cultivation of inner peace</li>
        </ul>
      `
    },
    'benefits-meditation': {
      title: "Benefits of Meditation",
      category: "Scientific Research",
      content: `
        <h1 class="text-3xl font-light mb-6">Benefits of Meditation</h1>
        <p class="mb-4">Scientific research has shown numerous benefits of regular meditation practice for both mental and physical health.</p>

        <h2 class="text-2xl font-light mt-8 mb-4">Mental Health Benefits</h2>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Reduced anxiety and depression</li>
          <li class="mb-2">Improved emotional regulation</li>
          <li class="mb-2">Better stress management</li>
          <li class="mb-2">Enhanced self-awareness</li>
        </ul>
      `
    },
    'types-meditation': {
      title: "Types of Meditation",
      category: "Meditation Basics",
      content: `
        <h1 class="text-3xl font-light mb-6">Types of Meditation</h1>
        <p class="mb-4">There are many different types of meditation practices, each with its own unique focus and benefits.</p>

        <h2 class="text-2xl font-light mt-8 mb-4">1. Mindfulness Meditation</h2>
        <p class="mb-4">Focus on the present moment, observing thoughts without judgment.</p>
      `
    },
    'mindful-work': {
      title: "Mindfulness at Work",
      category: "Mindful Living",
      content: `
        <h1 class="text-3xl font-light mb-6">Mindfulness at Work</h1>
        <p class="mb-4">Learn how to incorporate mindfulness practices into your workday.</p>

        <h2 class="text-2xl font-light mt-8 mb-4">Benefits in the Workplace</h2>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Improved focus and productivity</li>
          <li class="mb-2">Better stress management</li>
          <li class="mb-2">Enhanced workplace relationships</li>
          <li class="mb-2">Reduced workplace anxiety</li>
        </ul>
      `
    },
    'breathing-techniques': {
      title: "Breathing Techniques",
      category: "Meditation Basics",
      content: `
        <h1 class="text-3xl font-light mb-6">Breathing Techniques</h1>
        <p class="mb-4">Discover various breathing exercises for relaxation and stress relief.</p>

        <h2 class="text-2xl font-light mt-8 mb-4">Basic Techniques</h2>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">4-7-8 Breathing</li>
          <li class="mb-2">Box Breathing</li>
          <li class="mb-2">Diaphragmatic Breathing</li>
          <li class="mb-2">Alternate Nostril Breathing</li>
        </ul>
      `
    }
  };

  const categories = [
    "Getting Started",
    "Meditation Basics",
    "Scientific Research",
    "Mental Health",
    "Stress Management",
    "Sleep & Rest",
    "Mindful Living"
  ];

  const popularArticles = [
    {
      id: 'understanding-mindfulness',
      title: "How to Start a Meditation Practice"
    },
    {
      id: 'benefits-meditation',
      title: "The Science Behind Mindfulness"
    },
    {
      id: 'types-meditation',
      title: "Managing Anxiety with Meditation"
    },
    {
      id: 'mindful-work',
      title: "Creating a Mindful Morning Routine"
    }
  ];

  useEffect(() => {
    const allArticles = Object.entries(articles).map(([id, article]) => ({
      id,
      ...article
    }));

    let filtered = allArticles;

    // Filter by search query
    if (searchQuery) {
      filtered = filtered.filter(article =>
        article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        article.category.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Filter by category
    if (selectedCategory) {
      filtered = filtered.filter(article =>
        article.category === selectedCategory
      );
    }

    setFilteredArticles(filtered);
  }, [searchQuery, selectedCategory]);

  const handleArticleClick = (articleId) => {
    if (articles[articleId]) {
      setSelectedArticle(articleId);
      // Reset filters when viewing an article
      setSearchQuery('');
      setSelectedCategory(null);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
    setSelectedArticle(null);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-light text-white mb-8">Mindfulness Wiki</h1>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-8">
          {selectedArticle && articles[selectedArticle] ? (
            <div className="bg-white/10 backdrop-blur-md rounded-lg p-6">
              <button 
                onClick={() => setSelectedArticle(null)}
                className="text-calm-300 hover:text-white transition-colors mb-6 flex items-center"
              >
                <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                Back to Articles
              </button>
              <div 
                className="prose prose-invert prose-lg max-w-none text-calm-100"
                dangerouslySetInnerHTML={{ __html: articles[selectedArticle].content }}
              />
            </div>
          ) : (
            <>
              {/* Featured Article */}
              <div 
                className="bg-white/10 backdrop-blur-md rounded-lg p-6 cursor-pointer"
                onClick={() => handleArticleClick('understanding-mindfulness')}
              >
                <h2 className="text-2xl font-semibold text-white mb-4">Understanding Mindfulness</h2>
                <p className="text-calm-100 mb-4">
                  Mindfulness is the practice of maintaining a nonjudgmental state of heightened or complete awareness of one's thoughts, emotions, or experiences on a moment-to-moment basis.
                </p>
                <button className="text-calm-300 hover:text-white transition-colors">
                  Read More →
                </button>
              </div>

              {/* Article Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {filteredArticles.map((article) => (
                  <div 
                    key={article.id}
                    className="bg-white/10 backdrop-blur-md rounded-lg p-6 cursor-pointer"
                    onClick={() => handleArticleClick(article.id)}
                  >
                    <div className="flex justify-between items-start mb-4">
                      <span className="text-calm-300 text-sm">{article.category}</span>
                    </div>
                    <h3 className="text-xl font-semibold text-white mb-2">{article.title}</h3>
                    <button className="text-calm-300 hover:text-white transition-colors text-sm">
                      Read Article →
                    </button>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Search */}
          <div className="bg-white/10 backdrop-blur-md rounded-lg p-6">
            <input
              type="search"
              placeholder="Search articles..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full bg-white/10 text-white placeholder-calm-200 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-calm-400"
            />
          </div>

          {/* Categories */}
          <div className="bg-white/10 backdrop-blur-md rounded-lg p-6">
            <h3 className="text-lg font-semibold text-white mb-4">Categories</h3>
            <ul className="space-y-2">
              {categories.map((category, index) => (
                <li key={index}>
                  <button 
                    onClick={() => handleCategoryClick(category)}
                    className={`text-sm transition-colors ${
                      selectedCategory === category
                        ? 'text-white font-semibold'
                        : 'text-calm-100 hover:text-white'
                    }`}
                  >
                    {category}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Popular Articles */}
          <div className="bg-white/10 backdrop-blur-md rounded-lg p-6">
            <h3 className="text-lg font-semibold text-white mb-4">Popular Articles</h3>
            <ul className="space-y-4">
              {popularArticles.map((article, index) => (
                <li key={index}>
                  <button 
                    onClick={() => handleArticleClick(article.id)}
                    className="text-calm-100 hover:text-white transition-colors text-sm text-left"
                  >
                    {article.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wiki;