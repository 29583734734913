import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { resources } from '../data/resources';

function Resources() {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const categories = useMemo(() => {
    const cats = new Set(resources.map(resource => resource.category));
    return ['all', ...Array.from(cats)];
  }, []);

  const filteredResources = useMemo(() => {
    let filtered = resources;
    
    if (selectedCategory !== 'all') {
      filtered = filtered.filter(resource => resource.category === selectedCategory);
    }
    
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(resource => 
        resource.title.toLowerCase().includes(query) ||
        resource.description.toLowerCase().includes(query) ||
        resource.tags.some(tag => tag.toLowerCase().includes(query))
      );
    }
    
    return filtered;
  }, [selectedCategory, searchQuery]);

  return (
    <>
      <Helmet>
        <title>Free Resources - HushBalance</title>
        <meta name="description" content="Download free mindfulness resources, meditation guides, and wellness tools to support your journey to inner peace." />
        <meta name="keywords" content="mindfulness resources, meditation guides, wellness tools, free downloads" />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-light text-white mb-8">Resources</h1>

        {/* Search and Filter */}
        <div className="mb-8 space-y-4">
          <input
            type="search"
            placeholder="Search resources..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full md:w-96 bg-white/10 text-white placeholder-calm-200 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-calm-400"
          />
          
          <div className="flex flex-wrap gap-2">
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full transition-colors ${
                  selectedCategory === category
                    ? 'bg-calm-600 text-white'
                    : 'bg-white/10 text-calm-100 hover:bg-white/20'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Resources Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredResources.map((resource) => (
            <Link 
              key={resource.id}
              to={`/resources/${resource.slug}`}
              className="bg-white/10 backdrop-blur-md rounded-lg overflow-hidden hover:bg-white/20 transition-all"
            >
              <img 
                src={resource.coverImage} 
                alt={resource.title}
                className="w-full h-48 object-cover"
                loading="lazy"
              />
              <div className="p-6">
                <div className="flex items-center gap-2 mb-2">
                  <span className="text-calm-300 text-sm">{resource.category}</span>
                  <span className="text-calm-200">•</span>
                  <span className="text-calm-200 text-sm">{resource.format}</span>
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">{resource.title}</h3>
                <p className="text-calm-100 mb-4">{resource.description}</p>
                <div className="flex items-center justify-between mt-4">
                  <div className="text-sm">
                    <p className="text-white">By {resource.author}</p>
                    <p className="text-calm-200">{resource.size}</p>
                  </div>
                  <button className="bg-calm-600 hover:bg-calm-500 text-white px-4 py-2 rounded-lg transition-colors text-sm">
                    Download
                  </button>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default Resources;