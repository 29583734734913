import React, { useState, useEffect, useRef } from 'react';
import BreathingAnimation from './BreathingAnimation';

function BreathingExercise() {
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPhase, setCurrentPhase] = useState('prepare');
  const [timer, setTimer] = useState(3);
  const intervalRef = useRef(null);

  const exercises = [
    {
      id: 'box-breathing',
      name: 'Box Breathing',
      description: 'Inhale, hold, exhale, and hold, each for 4 seconds.',
      phases: [
        { name: 'inhale', duration: 4, instruction: 'Inhale slowly through your nose' },
        { name: 'hold1', duration: 4, instruction: 'Hold your breath gently' },
        { name: 'exhale', duration: 4, instruction: 'Exhale slowly through your mouth' },
        { name: 'hold2', duration: 4, instruction: 'Hold your breath gently' }
      ]
    },
    {
      id: '478-breathing',
      name: '4-7-8 Breathing',
      description: 'Inhale for 4, hold for 7, exhale for 8 seconds.',
      phases: [
        { name: 'inhale', duration: 4, instruction: 'Inhale quietly through your nose' },
        { name: 'hold', duration: 7, instruction: 'Hold your breath' },
        { name: 'exhale', duration: 8, instruction: 'Exhale completely through your mouth' }
      ]
    },
    {
      id: 'deep-breathing',
      name: 'Deep Breathing',
      description: 'Simple deep breaths to calm the mind.',
      phases: [
        { name: 'inhale', duration: 4, instruction: 'Breathe in deeply through your nose' },
        { name: 'exhale', duration: 4, instruction: 'Release your breath through your mouth' }
      ]
    }
  ];

  useEffect(() => {
    if (!isPlaying) {
      clearInterval(intervalRef.current);
      return;
    }

    if (currentPhase === 'prepare') {
      intervalRef.current = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            setCurrentPhase(selectedExercise.phases[0].name);
            setTimer(selectedExercise.phases[0].duration);
            return selectedExercise.phases[0].duration;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      intervalRef.current = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            const currentPhaseIndex = selectedExercise.phases.findIndex(p => p.name === currentPhase);
            const nextPhaseIndex = (currentPhaseIndex + 1) % selectedExercise.phases.length;
            setCurrentPhase(selectedExercise.phases[nextPhaseIndex].name);
            return selectedExercise.phases[nextPhaseIndex].duration;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalRef.current);
  }, [isPlaying, currentPhase, selectedExercise]);

  const getCurrentInstruction = () => {
    if (currentPhase === 'prepare') {
      return 'Prepare to begin...';
    }
    return selectedExercise.phases.find(p => p.name === currentPhase).instruction;
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const resetExercise = () => {
    setIsPlaying(false);
    setSelectedExercise(null);
    setCurrentPhase('prepare');
    setTimer(3);
    clearInterval(intervalRef.current);
  };

  return (
    <div className="max-w-3xl mx-auto">
      {!selectedExercise ? (
        <div className="grid grid-cols-1 gap-6">
          {exercises.map((exercise) => (
            <div
              key={exercise.id}
              className="bg-white/10 backdrop-blur-md p-6 rounded-lg hover:bg-white/20 transition-all"
            >
              <h3 className="text-xl font-semibold text-white mb-2">{exercise.name}</h3>
              <p className="text-calm-100 mb-4">{exercise.description}</p>
              <button
                onClick={() => {
                  setSelectedExercise(exercise);
                  setCurrentPhase('prepare');
                  setTimer(3);
                  setIsPlaying(true);
                }}
                className="bg-calm-600 hover:bg-calm-500 text-white px-6 py-2 rounded-lg transition-colors"
              >
                Start Exercise
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-white/10 backdrop-blur-md rounded-lg p-8">
          <div className="min-h-[600px] flex flex-col items-center justify-between">
            <div className="text-center mb-8">
              <h2 className="text-2xl font-semibold text-white mb-2">{selectedExercise.name}</h2>
              <p className="text-calm-100">{getCurrentInstruction()}</p>
            </div>

            <BreathingAnimation 
              phase={currentPhase} 
              duration={timer}
            />

            <div className="flex justify-center space-x-4 mt-8">
              <button
                onClick={togglePlayPause}
                className="bg-calm-600 hover:bg-calm-500 text-white px-6 py-2 rounded-lg transition-colors"
              >
                {isPlaying ? 'Pause' : 'Resume'}
              </button>
              <button
                onClick={resetExercise}
                className="bg-white/10 hover:bg-white/20 text-white px-6 py-2 rounded-lg transition-colors"
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BreathingExercise;