import React, { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import ComingSoonModal from './ComingSoonModal';

function Layout() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShopClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-calm-800 to-calm-900">
      {/* Navigation */}
      <nav className="fixed top-0 left-0 w-full z-50 bg-white/10 backdrop-blur-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <Link to="/" className="flex items-center space-x-2">
              <span className="text-xl font-semibold text-white">HushBalance</span>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/meditation" className="text-calm-100 hover:text-white transition-colors">Meditation</Link>
              <Link to="/tools" className="text-calm-100 hover:text-white transition-colors">Tools</Link>
              <Link to="/wiki" className="text-calm-100 hover:text-white transition-colors">Wiki</Link>
              <Link to="/blog" className="text-calm-100 hover:text-white transition-colors">Blog</Link>
              <Link to="/resources" className="text-calm-100 hover:text-white transition-colors">Resources</Link>
            </div>

            {/* Mobile Menu Button */}
            <button 
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="md:hidden text-white focus:outline-none"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className={`md:hidden bg-white/10 backdrop-blur-md ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link to="/meditation" className="block px-3 py-2 text-calm-100 hover:text-white transition-colors">Meditation</Link>
            <Link to="/tools" className="block px-3 py-2 text-calm-100 hover:text-white transition-colors">Tools</Link>
            <Link to="/wiki" className="block px-3 py-2 text-calm-100 hover:text-white transition-colors">Wiki</Link>
            <Link to="/blog" className="block px-3 py-2 text-calm-100 hover:text-white transition-colors">Blog</Link>
            <Link to="/resources" className="block px-3 py-2 text-calm-100 hover:text-white transition-colors">Resources</Link>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="pt-16">
        <Outlet />
      </main>

      {/* Footer */}
      <footer className="bg-white/5 backdrop-blur-md mt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Company Info */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-white">HushBalance</h3>
              <p className="text-calm-100">
                Your journey to inner peace starts here. Join our community and discover the power of mindfulness.
              </p>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li><Link to="/meditation" className="text-calm-100 hover:text-white transition-colors">Meditation</Link></li>
                <li><Link to="/tools" className="text-calm-100 hover:text-white transition-colors">Tools</Link></li>
                <li><Link to="/wiki" className="text-calm-100 hover:text-white transition-colors">Wiki</Link></li>
                <li><Link to="/blog" className="text-calm-100 hover:text-white transition-colors">Blog</Link></li>
                <li><Link to="/resources" className="text-calm-100 hover:text-white transition-colors">Resources</Link></li>
              </ul>
            </div>

            {/* Resources */}
            <div>
              <h3 className="text-lg font-semibold text-white mb-4">Resources</h3>
              <ul className="space-y-2">
                <li><Link to="/about" className="text-calm-100 hover:text-white transition-colors">About Us</Link></li>
                <li><Link to="/privacy" className="text-calm-100 hover:text-white transition-colors">Privacy Policy</Link></li>
                <li><Link to="/terms" className="text-calm-100 hover:text-white transition-colors">Terms of Service</Link></li>
              </ul>
            </div>

            {/* Contact */}
            <div>
              <h3 className="text-lg font-semibold text-white mb-4">Contact</h3>
              <ul className="space-y-2">
                <li className="text-calm-100">
                  <span className="block">WhatsApp:</span>
                  <a href="https://wa.me/8613014591168" className="hover:text-white transition-colors">+86 130 1459 1168</a>
                </li>
                <li className="text-calm-100">
                  <span className="block">Facebook:</span>
                  <a href="mailto:Leah.Recycling1168@gmail.com" className="hover:text-white transition-colors">Leah.Recycling1168@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>

          {/* Copyright */}
          <div className="border-t border-white/10 mt-8 pt-8 text-center">
            <p className="text-calm-200">
              © {new Date().getFullYear()} HushBalance. All rights reserved.
            </p>
          </div>
        </div>
      </footer>

      {/* Coming Soon Modal */}
      <ComingSoonModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
      />
    </div>
  );
}

export default Layout;