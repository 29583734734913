import React, { useState } from 'react';

function StressAssessment() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);

  const questions = [
    {
      text: "How often do you feel overwhelmed by your responsibilities?",
      options: [
        { value: 1, text: "Rarely or never" },
        { value: 2, text: "Sometimes" },
        { value: 3, text: "Often" },
        { value: 4, text: "Very often" }
      ]
    },
    {
      text: "How well do you sleep at night?",
      options: [
        { value: 1, text: "Very well" },
        { value: 2, text: "Fairly well" },
        { value: 3, text: "Not very well" },
        { value: 4, text: "Poorly" }
      ]
    },
    {
      text: "How often do you experience physical tension (e.g., headaches, muscle tension)?",
      options: [
        { value: 1, text: "Rarely or never" },
        { value: 2, text: "Sometimes" },
        { value: 3, text: "Often" },
        { value: 4, text: "Very often" }
      ]
    },
    {
      text: "How difficult is it for you to relax?",
      options: [
        { value: 1, text: "Not at all difficult" },
        { value: 2, text: "Somewhat difficult" },
        { value: 3, text: "Very difficult" },
        { value: 4, text: "Extremely difficult" }
      ]
    },
    {
      text: "How often do you feel irritable or short-tempered?",
      options: [
        { value: 1, text: "Rarely or never" },
        { value: 2, text: "Sometimes" },
        { value: 3, text: "Often" },
        { value: 4, text: "Very often" }
      ]
    }
  ];

  const handleAnswer = (value) => {
    const newAnswers = { ...answers, [currentQuestion]: value };
    setAnswers(newAnswers);
    
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
    }
  };

  const calculateResults = () => {
    const total = Object.values(answers).reduce((sum, value) => sum + value, 0);
    const average = total / questions.length;
    
    if (average <= 1.5) return "Low stress level";
    if (average <= 2.5) return "Moderate stress level";
    if (average <= 3.5) return "High stress level";
    return "Very high stress level";
  };

  const getStressScore = () => {
    const total = Object.values(answers).reduce((sum, value) => sum + value, 0);
    const percentage = ((total - 5) / 15) * 100; // Normalize to 0-100%
    return Math.round(percentage);
  };

  const getRecommendations = () => {
    const stressLevel = calculateResults();
    
    const recommendations = {
      "Low stress level": [
        "Continue your effective stress management practices",
        "Maintain regular exercise and relaxation routines",
        "Practice preventive self-care",
        "Consider sharing your successful strategies with others"
      ],
      "Moderate stress level": [
        "Incorporate 10-15 minutes of daily meditation",
        "Practice deep breathing exercises 3 times daily",
        "Ensure 7-8 hours of quality sleep",
        "Take regular breaks during work",
        "Consider limiting caffeine intake"
      ],
      "High stress level": [
        "Prioritize immediate stress reduction activities",
        "Consider talking to a counselor or therapist",
        "Review and adjust your work-life balance",
        "Implement a daily relaxation routine",
        "Reduce commitments where possible",
        "Create a calming bedtime routine"
      ],
      "Very high stress level": [
        "Seek professional support as soon as possible",
        "Make immediate lifestyle changes to reduce stress",
        "Practice daily relaxation techniques",
        "Consider taking time off for self-care",
        "Delegate responsibilities where possible",
        "Focus on basic self-care: sleep, nutrition, and exercise"
      ]
    };

    return recommendations[stressLevel] || [];
  };

  const getDetailedAnalysis = () => {
    const score = getStressScore();
    if (score <= 25) {
      return {
        color: "text-green-400",
        icon: "😌",
        message: "Your stress levels are well managed. You've developed good coping mechanisms and healthy habits."
      };
    } else if (score <= 50) {
      return {
        color: "text-yellow-400",
        icon: "😊",
        message: "You're experiencing moderate stress. While manageable, there's room for improvement in your stress management techniques."
      };
    } else if (score <= 75) {
      return {
        color: "text-orange-400",
        icon: "😟",
        message: "Your stress levels are elevated. It's important to take action now to prevent potential health impacts."
      };
    } else {
      return {
        color: "text-red-400",
        icon: "😰",
        message: "Your stress levels are very high. It's crucial to make immediate changes and seek professional support."
      };
    }
  };

  const resetAssessment = () => {
    setCurrentQuestion(0);
    setAnswers({});
    setShowResults(false);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white/10 backdrop-blur-md rounded-lg p-8">
        <h2 className="text-2xl font-semibold text-white mb-6">Stress Assessment</h2>

        {!showResults ? (
          <div>
            <div className="mb-8">
              <div className="text-calm-200 text-sm mb-2">
                Question {currentQuestion + 1} of {questions.length}
              </div>
              <div className="w-full bg-white/10 rounded-full h-2">
                <div
                  className="bg-calm-500 h-2 rounded-full transition-all duration-300"
                  style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}
                />
              </div>
            </div>

            <h3 className="text-xl text-white mb-6">{questions[currentQuestion].text}</h3>
            
            <div className="space-y-3">
              {questions[currentQuestion].options.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleAnswer(option.value)}
                  className="w-full text-left bg-white/5 hover:bg-white/10 text-calm-100 p-4 rounded-lg transition-colors"
                >
                  {option.text}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <div className="text-center mb-8">
              <div className="text-6xl mb-4">{getDetailedAnalysis().icon}</div>
              <h3 className="text-2xl text-white mb-2">Your Results</h3>
              <p className={`text-3xl font-semibold mb-4 ${getDetailedAnalysis().color}`}>
                {calculateResults()}
              </p>
              <div className="w-full bg-white/10 rounded-full h-4 mb-4">
                <div
                  className={`h-4 rounded-full transition-all duration-1000 ${getDetailedAnalysis().color}`}
                  style={{ width: `${getStressScore()}%` }}
                />
              </div>
              <p className="text-calm-100 mb-6">
                {getDetailedAnalysis().message}
              </p>
            </div>
            
            <div className="bg-white/5 rounded-lg p-6 mb-6">
              <h4 className="text-lg text-white mb-4">Personalized Recommendations:</h4>
              <ul className="space-y-3">
                {getRecommendations().map((recommendation, index) => (
                  <li key={index} className="text-calm-100 flex items-start">
                    <span className="mr-2">•</span>
                    {recommendation}
                  </li>
                ))}
              </ul>
            </div>

            <div className="text-center">
              <button
                onClick={resetAssessment}
                className="bg-calm-600 hover:bg-calm-500 text-white px-6 py-2 rounded-lg transition-colors"
              >
                Take Assessment Again
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StressAssessment;