import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';

function ProductDetail() {
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState(0);
  const [email, setEmail] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const products = {
    'mindfulness-beginners-guide': {
      name: "Mindfulness for Beginners Guide",
      price: "$12.99",
      type: "digital",
      description: "Comprehensive PDF guide to start your mindfulness journey.",
      longDescription: `
        Start your mindfulness journey with our comprehensive guide designed specifically for beginners. This carefully crafted PDF guide provides everything you need to establish a strong foundation in mindfulness practice.

        Whether you're looking to reduce stress, improve focus, or find more peace in your daily life, this guide will walk you through the essential concepts and practices of mindfulness in a clear, accessible way.
      `,
      features: [
        "Instant digital delivery",
        "100+ pages of practical content",
        "Beginner-friendly exercises",
        "Scientific background",
        "Progress tracking tools",
        "Lifetime access to updates"
      ],
      benefits: [
        "Learn at your own pace",
        "Practice anywhere, anytime",
        "Track your progress",
        "Access to exclusive resources",
        "Regular content updates"
      ],
      specifications: {
        "Format": "PDF (Digital Download)",
        "Pages": "100+",
        "Language": "English",
        "Last Updated": "February 2024",
        "Access": "Lifetime",
        "Compatibility": "All devices"
      },
      contents: [
        "Chapter 1: Introduction to Mindfulness",
        "Chapter 2: The Science Behind Mindfulness",
        "Chapter 3: Getting Started with Practice",
        "Chapter 4: Daily Mindfulness Exercises",
        "Chapter 5: Overcoming Common Challenges",
        "Chapter 6: Advanced Techniques",
        "Bonus: Printable Worksheets & Tracking Tools"
      ],
      images: [
        "/products/mindfulness-guide-preview-1.jpg",
        "/products/mindfulness-guide-preview-2.jpg",
        "/products/mindfulness-guide-preview-3.jpg",
        "/products/mindfulness-guide-preview-4.jpg"
      ],
      reviews: [
        {
          author: "Emma S.",
          rating: 5,
          comment: "Perfect for beginners! The guide breaks down complex concepts into easy-to-understand pieces.",
          date: "2024-02-05"
        },
        {
          author: "James L.",
          rating: 5,
          comment: "The practical exercises and worksheets are incredibly helpful. Great value!",
          date: "2024-02-01"
        }
      ]
    },
    // ... other product details ...
  };

  const product = products[id];

  const handlePurchase = (e) => {
    e.preventDefault();
    // Here you would typically integrate with a payment processor
    // For now, we'll just show a success message
    setShowSuccessMessage(true);
    // Reset after 5 seconds
    setTimeout(() => setShowSuccessMessage(false), 5000);
  };

  if (!product) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <h1 className="text-4xl font-light text-white mb-4">Product Not Found</h1>
          <Link to="/products" className="text-calm-300 hover:text-white transition-colors">
            ← Back to Shop
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/products" className="text-calm-300 hover:text-white transition-colors inline-block mb-8">
        ← Back to Shop
      </Link>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {/* Product Preview */}
        <div className="space-y-4">
          <div className="bg-white/10 backdrop-blur-md rounded-lg overflow-hidden">
            <img
              src={product.images[selectedImage]}
              alt={product.name}
              className="w-full h-[500px] object-cover"
            />
          </div>
          <div className="grid grid-cols-4 gap-4">
            {product.images.map((image, index) => (
              <button
                key={index}
                onClick={() => setSelectedImage(index)}
                className={`bg-white/10 backdrop-blur-md rounded-lg overflow-hidden ${
                  selectedImage === index ? 'ring-2 ring-calm-500' : ''
                }`}
              >
                <img
                  src={image}
                  alt={`${product.name} preview ${index + 1}`}
                  className="w-full h-20 object-cover"
                />
              </button>
            ))}
          </div>
        </div>

        {/* Product Info */}
        <div className="space-y-8">
          <div>
            <div className="flex items-center gap-4 mb-2">
              <h1 className="text-3xl font-light text-white">{product.name}</h1>
              {product.type === 'digital' && (
                <span className="bg-calm-600 text-white px-3 py-1 rounded-full text-sm">
                  Digital Product
                </span>
              )}
            </div>
            <p className="text-2xl text-calm-300">{product.price}</p>
          </div>

          <div className="prose prose-invert">
            <p className="text-calm-100">{product.description}</p>
            <p className="text-calm-100">{product.longDescription}</p>
          </div>

          {/* Features */}
          <div>
            <h2 className="text-xl font-semibold text-white mb-4">Features</h2>
            <ul className="space-y-2">
              {product.features.map((feature, index) => (
                <li key={index} className="flex items-center text-calm-100">
                  <svg className="w-5 h-5 mr-2 text-calm-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
          </div>

          {/* Contents */}
          {product.type === 'digital' && (
            <div>
              <h2 className="text-xl font-semibold text-white mb-4">What's Included</h2>
              <ul className="space-y-2">
                {product.contents.map((content, index) => (
                  <li key={index} className="flex items-center text-calm-100">
                    <svg className="w-5 h-5 mr-2 text-calm-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    {content}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Purchase Form for Digital Products */}
          {product.type === 'digital' && (
            <div className="bg-white/5 p-6 rounded-lg">
              <h3 className="text-lg font-semibold text-white mb-4">Purchase & Download</h3>
              <form onSubmit={handlePurchase} className="space-y-4">
                <div>
                  <label htmlFor="email" className="block text-calm-100 mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full bg-white/10 text-white placeholder-calm-200 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-calm-400"
                    required
                  />
                  <p className="text-calm-200 text-sm mt-2">
                    The download link will be sent to this email after purchase.
                  </p>
                </div>

                {showSuccessMessage && (
                  <div className="bg-green-500/10 text-green-400 p-4 rounded-lg">
                    Thank you for your purchase! Check your email for download instructions.
                  </div>
                )}

                <button
                  type="submit"
                  className="w-full bg-calm-600 hover:bg-calm-500 text-white py-3 rounded-lg transition-colors"
                >
                  Purchase Now ({product.price})
                </button>
              </form>
            </div>
          )}

          {/* Reviews */}
          <div>
            <h2 className="text-xl font-semibold text-white mb-4">Customer Reviews</h2>
            <div className="space-y-4">
              {product.reviews.map((review, index) => (
                <div key={index} className="bg-white/5 p-4 rounded-lg">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-white">{review.author}</span>
                    <div className="flex">
                      {[...Array(review.rating)].map((_, i) => (
                        <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                    </div>
                  </div>
                  <p className="text-calm-100">{review.comment}</p>
                  <p className="text-calm-200 text-sm mt-2">{new Date(review.date).toLocaleDateString()}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;