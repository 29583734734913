import React from 'react';
import { FaTwitter, FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa';

function About() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-light text-white mb-8">About HushBalance</h1>

      {/* Mission Statement */}
      <div className="bg-white/10 backdrop-blur-md rounded-lg p-8 mb-12">
        <h2 className="text-2xl font-semibold text-white mb-4">Our Mission</h2>
        <p className="text-calm-100 text-lg leading-relaxed">
          At HushBalance, we believe that everyone deserves to find their inner peace. Our mission is to make mindfulness and meditation accessible to all, providing the tools and guidance needed for a more balanced, mindful life.
        </p>
      </div>

      {/* Values Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        {[
          {
            title: "Accessibility",
            description: "Making mindfulness practices available to everyone, regardless of experience level.",
            icon: "🌍"
          },
          {
            title: "Community",
            description: "Building a supportive environment for shared growth and learning.",
            icon: "🤝"
          },
          {
            title: "Innovation",
            description: "Combining traditional practices with modern technology for better results.",
            icon: "💡"
          }
        ].map((value, index) => (
          <div key={index} className="bg-white/10 backdrop-blur-md rounded-lg p-6">
            <div className="text-4xl mb-4">{value.icon}</div>
            <h3 className="text-xl font-semibold text-white mb-2">{value.title}</h3>
            <p className="text-calm-100">{value.description}</p>
          </div>
        ))}
      </div>

      {/* Team Section */}
      <div className="mb-12">
        <h2 className="text-2xl font-semibold text-white mb-6">Our Team</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[
            {
              name: "Sarah Mitchell",
              role: "Founder & Lead Instructor",
              bio: "Certified mindfulness teacher with 10+ years of experience.",
              image: "/team/sarah.jpg"
            },
            {
              name: "David Chen",
              role: "Head of Content",
              bio: "Meditation practitioner and wellness content creator.",
              image: "/team/david.jpg"
            },
            {
              name: "Dr. Emma Watson",
              role: "Research Director",
              bio: "PhD in Psychology, specializing in mindfulness studies.",
              image: "/team/emma.jpg"
            },
            {
              name: "Michael Brown",
              role: "Community Manager",
              bio: "Dedicated to building and nurturing our mindful community.",
              image: "/team/michael.jpg"
            }
          ].map((member, index) => (
            <div key={index} className="bg-white/10 backdrop-blur-md rounded-lg p-6">
              <div className="mb-4 relative">
                <div className="w-24 h-24 rounded-full bg-calm-500 mx-auto"></div>
              </div>
              <div className="text-center">
                <h3 className="text-xl font-semibold text-white mb-1">{member.name}</h3>
                <p className="text-calm-300 text-sm mb-2">{member.role}</p>
                <p className="text-calm-100 text-sm">{member.bio}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Contact Section */}
      <div className="bg-white/10 backdrop-blur-md rounded-lg p-8">
        <h2 className="text-2xl font-semibold text-white mb-6">Get in Touch</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <p className="text-calm-100 mb-4">
              Have questions or want to learn more about HushBalance? We'd love to hear from you.
            </p>
            <div className="space-y-4">
              <div>
                <h4 className="text-white font-semibold mb-1">Email</h4>
                <a 
                  href="mailto:cailiangihg@163.com" 
                  className="text-calm-100 hover:text-white transition-colors"
                >
                  cailiangihg@163.com
                </a>
              </div>
              <div>
                <h4 className="text-white font-semibold mb-3">Connect With Us</h4>
                <div className="flex space-x-4">
                  <a href="https://twitter.com/hushbalance" target="_blank" rel="noopener noreferrer" 
                     className="text-calm-100 hover:text-white transition-colors">
                    <FaTwitter size={24} />
                  </a>
                  <a href="https://instagram.com/hushbalance" target="_blank" rel="noopener noreferrer"
                     className="text-calm-100 hover:text-white transition-colors">
                    <FaInstagram size={24} />
                  </a>
                  <a href="https://linkedin.com/company/hushbalance" target="_blank" rel="noopener noreferrer"
                     className="text-calm-100 hover:text-white transition-colors">
                    <FaLinkedin size={24} />
                  </a>
                  <a href="https://facebook.com/hushbalance" target="_blank" rel="noopener noreferrer"
                     className="text-calm-100 hover:text-white transition-colors">
                    <FaFacebook size={24} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center md:text-left">
            <p className="text-calm-100 mb-4">
              Send us an email directly:
            </p>
            <a 
              href="mailto:cailiangihg@163.com"
              className="inline-block bg-calm-600 hover:bg-calm-500 text-white px-6 py-3 rounded-lg transition-colors"
            >
              Email Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;