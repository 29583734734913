import React from 'react';
import { Link } from 'react-router-dom';

function Meditation() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-light text-white mb-8">Guided Meditations</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Featured Meditation */}
        <div className="col-span-full bg-white/10 backdrop-blur-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold text-white mb-4">Featured: Deep Relaxation Journey</h2>
          <p className="text-calm-100 mb-4">A 20-minute guided meditation for complete relaxation and stress relief.</p>
          <Link 
            to="/meditation/deep-relaxation"
            className="inline-block bg-calm-600 hover:bg-calm-500 text-white px-6 py-2 rounded-lg transition-colors"
          >
            Start Session
          </Link>
        </div>

        {/* Meditation Cards */}
        {[
          {
            id: 'mindful-breathing',
            title: "Mindful Breathing",
            duration: "10 min",
            level: "Beginner",
            description: "Learn the basics of mindful breathing techniques."
          },
          {
            id: 'body-scan',
            title: "Body Scan",
            duration: "15 min",
            level: "Intermediate",
            description: "Progressive relaxation through body awareness."
          },
          {
            id: 'loving-kindness',
            title: "Loving-Kindness",
            duration: "20 min",
            level: "All Levels",
            description: "Cultivate compassion for yourself and others."
          },
          {
            id: 'sleep-well',
            title: "Sleep Well",
            duration: "30 min",
            level: "All Levels",
            description: "Gentle guidance into restful sleep."
          },
          {
            id: 'morning-energy',
            title: "Morning Energy",
            duration: "10 min",
            level: "All Levels",
            description: "Start your day with clarity and purpose."
          },
          {
            id: 'stress-relief',
            title: "Stress Relief",
            duration: "15 min",
            level: "All Levels",
            description: "Release tension and find calm."
          }
        ].map((meditation) => (
          <div key={meditation.id} className="bg-white/10 backdrop-blur-md rounded-lg p-6 hover:bg-white/20 transition-all">
            <h3 className="text-xl font-semibold text-white mb-2">{meditation.title}</h3>
            <div className="flex space-x-4 text-calm-200 text-sm mb-3">
              <span>{meditation.duration}</span>
              <span>•</span>
              <span>{meditation.level}</span>
            </div>
            <p className="text-calm-100 mb-4">{meditation.description}</p>
            <Link
              to={`/meditation/${meditation.id}`}
              className="inline-block bg-calm-600 hover:bg-calm-500 text-white px-4 py-2 rounded-lg transition-colors text-sm"
            >
              Begin Practice
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Meditation;