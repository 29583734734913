import React from 'react';

function BreathingAnimation({ phase, duration }) {
  return (
    <div className="relative h-[400px] w-full flex items-center justify-center">
      {/* Background Glow */}
      <div 
        className={`absolute w-64 h-64 rounded-full blur-xl transition-all duration-1000 ease-in-out
          ${phase === 'inhale' ? 'scale-125 opacity-30' : 
            phase === 'exhale' ? 'scale-90 opacity-20' : 
            'scale-100 opacity-25'}`}
        style={{
          background: 'radial-gradient(circle at center, rgba(139, 92, 246, 0.4), transparent 70%)',
        }}
      />

      {/* Main Breathing Circle */}
      <div 
        className={`absolute w-56 h-56 rounded-full transition-all duration-1000 ease-in-out
          ${phase === 'inhale' ? 'scale-125' : 
            phase === 'exhale' ? 'scale-90' : 
            'scale-100'}`}
        style={{
          background: 'radial-gradient(circle at center, rgba(139, 92, 246, 0.1), transparent)',
          boxShadow: '0 0 40px rgba(139, 92, 246, 0.2)',
          border: '2px solid rgba(139, 92, 246, 0.3)'
        }}
      />

      {/* Timer Display */}
      <div className="absolute top-1/2 -translate-y-[60px] text-4xl font-light text-white z-10">
        {duration}
      </div>

      {/* Phase Text */}
      <div className="absolute top-1/2 translate-y-[20px] text-xl text-white/80 tracking-wider uppercase">
        {phase === 'inhale' && 'Inhale'}
        {phase === 'exhale' && 'Exhale'}
        {phase === 'hold1' && 'Hold'}
        {phase === 'hold2' && 'Hold'}
      </div>

      {/* Progress Ring */}
      <svg 
        className="absolute w-64 h-64"
        viewBox="0 0 100 100"
      >
        <circle
          cx="50"
          cy="50"
          r="48"
          fill="none"
          stroke="rgba(139, 92, 246, 0.1)"
          strokeWidth="1"
        />
        <circle
          cx="50"
          cy="50"
          r="48"
          fill="none"
          stroke="rgba(139, 92, 246, 0.3)"
          strokeWidth="1"
          strokeDasharray="301.59"
          strokeDashoffset={301.59 * (1 - duration / (phase === 'hold' ? 7 : 4))}
          style={{ transition: 'stroke-dashoffset 1s linear' }}
        />
      </svg>
    </div>
  );
}

export default BreathingAnimation;