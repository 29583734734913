import React from 'react';

function Privacy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-light text-white mb-8">Privacy Policy</h1>

      {/* Rest of the Privacy component code remains the same */}
      <div className="bg-white/10 backdrop-blur-md rounded-lg p-8">
        <div className="prose prose-invert max-w-none">
          <p className="text-calm-100 mb-6">
            Last updated: January 1, 2024
          </p>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-white mb-4">1. Introduction</h2>
            <p className="text-calm-100 mb-4">
              At HushBalance, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
            </p>
          </section>

          {/* Rest of the sections remain the same */}
        </div>
      </div>
    </div>
  );
}

export default Privacy;