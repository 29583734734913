import React from 'react';

function ComingSoonModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="relative bg-gradient-to-b from-calm-800 to-calm-900 rounded-lg p-8 max-w-md mx-4 text-center">
        <div className="text-4xl mb-4">🌟</div>
        <h2 className="text-2xl font-light text-white mb-4">Coming Soon</h2>
        <p className="text-calm-100 mb-6">
          We're working hard to bring you a curated collection of mindful products. Stay tuned for our upcoming launch!
        </p>
        <button
          onClick={onClose}
          className="bg-calm-600 hover:bg-calm-500 text-white px-6 py-2 rounded-lg transition-colors"
        >
          Got it
        </button>
      </div>
    </div>
  );
}

export default ComingSoonModal;