import React, { useState, useEffect } from 'react';

function SleepJournal() {
  const [entries, setEntries] = useState(() => {
    const saved = localStorage.getItem('sleepJournal');
    return saved ? JSON.parse(saved) : [];
  });
  const [newEntry, setNewEntry] = useState({
    date: new Date().toISOString().split('T')[0],
    bedTime: '',
    wakeTime: '',
    quality: 3,
    mood: 3,
    notes: ''
  });

  useEffect(() => {
    localStorage.setItem('sleepJournal', JSON.stringify(entries));
  }, [entries]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEntries([{ ...newEntry, id: Date.now() }, ...entries]);
    setNewEntry({
      date: new Date().toISOString().split('T')[0],
      bedTime: '',
      wakeTime: '',
      quality: 3,
      mood: 3,
      notes: ''
    });
  };

  const calculateSleepDuration = (bedTime, wakeTime) => {
    const [bedHour, bedMinute] = bedTime.split(':');
    const [wakeHour, wakeMinute] = wakeTime.split(':');
    
    let hours = parseInt(wakeHour) - parseInt(bedHour);
    let minutes = parseInt(wakeMinute) - parseInt(bedMinute);
    
    if (hours < 0) hours += 24;
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }
    
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white/10 backdrop-blur-md rounded-lg p-8 mb-8">
        <h2 className="text-2xl font-semibold text-white mb-6">New Sleep Entry</h2>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-white mb-2">Date</label>
              <input
                type="date"
                value={newEntry.date}
                onChange={(e) => setNewEntry({ ...newEntry, date: e.target.value })}
                className="w-full bg-white/5 text-white p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-calm-400"
                required
              />
            </div>
            
            <div>
              <label className="block text-white mb-2">Bed Time</label>
              <input
                type="time"
                value={newEntry.bedTime}
                onChange={(e) => setNewEntry({ ...newEntry, bedTime: e.target.value })}
                className="w-full bg-white/5 text-white p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-calm-400"
                required
              />
            </div>
            
            <div>
              <label className="block text-white mb-2">Wake Time</label>
              <input
                type="time"
                value={newEntry.wakeTime}
                onChange={(e) => setNewEntry({ ...newEntry, wakeTime: e.target.value })}
                className="w-full bg-white/5 text-white p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-calm-400"
                required
              />
            </div>
            
            <div>
              <label className="block text-white mb-2">Sleep Quality (1-5)</label>
              <input
                type="range"
                min="1"
                max="5"
                value={newEntry.quality}
                onChange={(e) => setNewEntry({ ...newEntry, quality: parseInt(e.target.value) })}
                className="w-full"
              />
              <div className="flex justify-between text-calm-200 text-sm">
                <span>Poor</span>
                <span>Excellent</span>
              </div>
            </div>
          </div>

          <div>
            <label className="block text-white mb-2">Notes</label>
            <textarea
              value={newEntry.notes}
              onChange={(e) => setNewEntry({ ...newEntry, notes: e.target.value })}
              className="w-full bg-white/5 text-white p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-calm-400"
              rows="3"
              placeholder="How did you sleep? Any dreams or disturbances?"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-calm-600 hover:bg-calm-500 text-white py-2 rounded-lg transition-colors"
          >
            Save Entry
          </button>
        </form>
      </div>

      <div className="bg-white/10 backdrop-blur-md rounded-lg p-8">
        <h3 className="text-xl font-semibold text-white mb-4">Sleep History</h3>
        
        {entries.length === 0 ? (
          <p className="text-calm-200">No sleep entries yet. Start tracking your sleep above!</p>
        ) : (
          <div className="space-y-4">
            {entries.map((entry) => (
              <div
                key={entry.id}
                className="bg-white/5 rounded-lg p-4"
              >
                <div className="flex justify-between items-start mb-2">
                  <span className="text-white">{new Date(entry.date).toLocaleDateString()}</span>
                  <span className="text-calm-300">
                    {calculateSleepDuration(entry.bedTime, entry.wakeTime)}
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-2 text-sm">
                  <div>
                    <span className="text-calm-200">Bed Time: </span>
                    <span className="text-calm-100">{entry.bedTime}</span>
                  </div>
                  <div>
                    <span className="text-calm-200">Wake Time: </span>
                    <span className="text-calm-100">{entry.wakeTime}</span>
                  </div>
                  <div>
                    <span className="text-calm-200">Quality: </span>
                    <span className="text-calm-100">{"⭐".repeat(entry.quality)}</span>
                  </div>
                </div>
                {entry.notes && (
                  <p className="text-calm-100 text-sm mt-2">{entry.notes}</p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default SleepJournal;