import React, { useState } from 'react';
import StressAssessment from '../components/tools/StressAssessment';
import MoodTracker from '../components/tools/MoodTracker';
import BreathingExercise from '../components/tools/BreathingExercise';
import SleepJournal from '../components/tools/SleepJournal';

function Tools() {
  const [activeTool, setActiveTool] = useState(null);

  const tools = [
    {
      id: 'stress-assessment',
      title: "Stress Assessment",
      description: "Evaluate your current stress levels and get personalized recommendations.",
      icon: "📊",
      component: StressAssessment
    },
    {
      id: 'mood-tracker',
      title: "Mood Tracker",
      description: "Track your daily mood patterns and emotional well-being.",
      icon: "📈",
      component: MoodTracker
    },
    {
      id: 'breathing-exercise',
      title: "Breathing Exercises",
      description: "Collection of breathing techniques for different situations.",
      icon: "🫁",
      component: BreathingExercise
    },
    {
      id: 'sleep-journal',
      title: "Sleep Journal",
      description: "Monitor your sleep patterns and improve your rest quality.",
      icon: "😴",
      component: SleepJournal
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-light text-white mb-8">Wellness Tools</h1>

      {activeTool ? (
        <div>
          <button
            onClick={() => setActiveTool(null)}
            className="text-calm-300 hover:text-white transition-colors mb-6 flex items-center"
          >
            <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Back to Tools
          </button>
          {React.createElement(tools.find(t => t.id === activeTool).component)}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {tools.map((tool) => (
            <div
              key={tool.id}
              className="bg-white/10 backdrop-blur-md rounded-lg p-6 hover:bg-white/20 transition-all cursor-pointer"
              onClick={() => setActiveTool(tool.id)}
            >
              <div className="text-4xl mb-4">{tool.icon}</div>
              <h3 className="text-xl font-semibold text-white mb-2">{tool.title}</h3>
              <p className="text-calm-100 mb-4">{tool.description}</p>
              <button className="bg-calm-600 hover:bg-calm-500 text-white px-4 py-2 rounded-lg transition-colors text-sm">
                Open Tool
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Tools;