import React from 'react';

function Forum() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-4xl font-light text-white">Community Forum</h1>
        <button className="bg-calm-600 hover:bg-calm-500 text-white px-6 py-2 rounded-lg transition-colors">
          New Discussion
        </button>
      </div>

      {/* Rest of the Forum component code remains the same */}
      {/* Categories */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {[
          {
            name: "General Discussion",
            count: "234 topics",
            icon: "💭"
          },
          {
            name: "Meditation Practice",
            count: "156 topics",
            icon: "🧘"
          },
          {
            name: "Success Stories",
            count: "98 topics",
            icon: "🌟"
          },
          {
            name: "Questions & Support",
            count: "167 topics",
            icon: "❓"
          }
        ].map((category, index) => (
          <div key={index} className="bg-white/10 backdrop-blur-md rounded-lg p-6">
            <div className="text-3xl mb-3">{category.icon}</div>
            <h3 className="text-lg font-semibold text-white mb-1">{category.name}</h3>
            <p className="text-calm-200 text-sm">{category.count}</p>
          </div>
        ))}
      </div>

      {/* Recent Discussions */}
      <div className="bg-white/10 backdrop-blur-md rounded-lg">
        <div className="p-4 border-b border-white/10">
          <h2 className="text-xl font-semibold text-white">Recent Discussions</h2>
        </div>
        
        {[
          {
            title: "How do you maintain a daily meditation practice?",
            author: "MindfulMary",
            replies: 23,
            views: 156,
            category: "Meditation Practice",
            timeAgo: "2 hours ago"
          },
          {
            title: "Breakthrough in my anxiety management",
            author: "PeacefulPete",
            replies: 45,
            views: 289,
            category: "Success Stories",
            timeAgo: "4 hours ago"
          },
          {
            title: "Best time of day for meditation?",
            author: "ZenMaster",
            replies: 34,
            views: 203,
            category: "General Discussion",
            timeAgo: "6 hours ago"
          },
          {
            title: "Dealing with distracting thoughts",
            author: "MindfulNewbie",
            replies: 56,
            views: 342,
            category: "Questions & Support",
            timeAgo: "8 hours ago"
          },
          {
            title: "Group meditation experiences",
            author: "CommunityGuide",
            replies: 28,
            views: 176,
            category: "General Discussion",
            timeAgo: "12 hours ago"
          }
        ].map((discussion, index) => (
          <div key={index} className="p-6 border-b border-white/10 hover:bg-white/5 transition-colors">
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-semibold text-white mb-2">{discussion.title}</h3>
                <div className="flex items-center space-x-4 text-sm">
                  <span className="text-calm-300">by {discussion.author}</span>
                  <span className="text-calm-200">in {discussion.category}</span>
                  <span className="text-calm-200">{discussion.timeAgo}</span>
                </div>
              </div>
              <div className="text-right text-sm">
                <p className="text-calm-100">{discussion.replies} replies</p>
                <p className="text-calm-200">{discussion.views} views</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Load More Button */}
      <div className="text-center mt-8">
        <button className="bg-calm-600 hover:bg-calm-500 text-white px-6 py-2 rounded-lg transition-colors">
          Load More Discussions
        </button>
      </div>
    </div>
  );
}

export default Forum;