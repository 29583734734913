import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { resources } from '../data/resources';

function ResourceDetail() {
  const { slug } = useParams();
  const navigate = useNavigate();
  
  const resource = resources.find(r => r.slug === slug);

  if (!resource) {
    navigate('/resources', { replace: true });
    return null;
  }

  const handleDownload = () => {
    // In a real application, you might want to:
    // 1. Track downloads
    // 2. Verify user authentication
    // 3. Handle different file types appropriately
    window.open(resource.downloadUrl, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>{`${resource.title} - HushBalance Resources`}</title>
        <meta name="description" content={resource.metaDescription} />
        <meta name="keywords" content={resource.keywords} />
        <link rel="canonical" href={`https://hushbalance.com/resources/${resource.slug}`} />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <Link to="/resources" className="text-calm-300 hover:text-white transition-colors inline-block mb-8">
          ← Back to Resources
        </Link>
        
        <div className="bg-white/10 backdrop-blur-md rounded-lg p-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <img 
                src={resource.coverImage} 
                alt={resource.title}
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            
            <div>
              <h1 className="text-3xl font-light text-white mb-4">{resource.title}</h1>
              <p className="text-calm-100 mb-6">{resource.description}</p>
              
              <div className="space-y-4 mb-8">
                <div className="flex items-center justify-between py-2 border-b border-white/10">
                  <span className="text-calm-200">Format</span>
                  <span className="text-white">{resource.format}</span>
                </div>
                <div className="flex items-center justify-between py-2 border-b border-white/10">
                  <span className="text-calm-200">Size</span>
                  <span className="text-white">{resource.size}</span>
                </div>
                {resource.duration && (
                  <div className="flex items-center justify-between py-2 border-b border-white/10">
                    <span className="text-calm-200">Duration</span>
                    <span className="text-white">{resource.duration}</span>
                  </div>
                )}
                <div className="flex items-center justify-between py-2 border-b border-white/10">
                  <span className="text-calm-200">Author</span>
                  <span className="text-white">{resource.author}</span>
                </div>
                <div className="flex items-center justify-between py-2 border-b border-white/10">
                  <span className="text-calm-200">Published</span>
                  <span className="text-white">
                    {new Date(resource.publishDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </span>
                </div>
              </div>

              <div className="flex flex-wrap gap-2 mb-8">
                {resource.tags.map(tag => (
                  <span 
                    key={tag}
                    className="bg-white/5 text-calm-200 px-3 py-1 rounded-full text-sm"
                  >
                    #{tag}
                  </span>
                ))}
              </div>

              <button
                onClick={handleDownload}
                className="w-full bg-calm-600 hover:bg-calm-500 text-white py-3 rounded-lg transition-colors"
              >
                Download {resource.format}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourceDetail;