import React, { useState, useEffect } from 'react';

function MoodTracker() {
  const [moods, setMoods] = useState(() => {
    const saved = localStorage.getItem('moodTracker');
    return saved ? JSON.parse(saved) : [];
  });
  const [selectedMood, setSelectedMood] = useState(null);
  const [note, setNote] = useState('');

  useEffect(() => {
    localStorage.setItem('moodTracker', JSON.stringify(moods));
  }, [moods]);

  const moodOptions = [
    { emoji: "😊", label: "Happy", color: "bg-green-500" },
    { emoji: "😌", label: "Calm", color: "bg-blue-500" },
    { emoji: "😔", label: "Sad", color: "bg-indigo-500" },
    { emoji: "😤", label: "Angry", color: "bg-red-500" },
    { emoji: "😰", label: "Anxious", color: "bg-yellow-500" },
    { emoji: "😴", label: "Tired", color: "bg-purple-500" }
  ];

  const addMoodEntry = () => {
    if (!selectedMood) return;

    const newEntry = {
      mood: selectedMood,
      note: note,
      timestamp: new Date().toISOString()
    };

    setMoods([newEntry, ...moods]);
    setSelectedMood(null);
    setNote('');
  };

  const formatDate = (dateString) => {
    const options = { 
      month: 'short', 
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white/10 backdrop-blur-md rounded-lg p-8 mb-8">
        <h2 className="text-2xl font-semibold text-white mb-6">How are you feeling?</h2>

        <div className="grid grid-cols-3 gap-4 mb-6">
          {moodOptions.map((mood) => (
            <button
              key={mood.label}
              onClick={() => setSelectedMood(mood)}
              className={`p-4 rounded-lg flex flex-col items-center ${
                selectedMood?.label === mood.label
                  ? 'bg-white/20'
                  : 'bg-white/5 hover:bg-white/10'
              } transition-colors`}
            >
              <span className="text-4xl mb-2">{mood.emoji}</span>
              <span className="text-calm-100">{mood.label}</span>
            </button>
          ))}
        </div>

        <textarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Add a note about how you're feeling... (optional)"
          className="w-full bg-white/5 text-white placeholder-calm-200 p-4 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-calm-400"
          rows="3"
        />

        <button
          onClick={addMoodEntry}
          disabled={!selectedMood}
          className={`w-full py-3 rounded-lg transition-colors ${
            selectedMood
              ? 'bg-calm-600 hover:bg-calm-500 text-white'
              : 'bg-white/5 text-calm-200 cursor-not-allowed'
          }`}
        >
          Save Entry
        </button>
      </div>

      <div className="bg-white/10 backdrop-blur-md rounded-lg p-8">
        <h3 className="text-xl font-semibold text-white mb-4">Mood History</h3>
        
        {moods.length === 0 ? (
          <p className="text-calm-200">No mood entries yet. Start tracking your moods above!</p>
        ) : (
          <div className="space-y-4">
            {moods.map((entry, index) => (
              <div
                key={index}
                className="bg-white/5 rounded-lg p-4 flex items-start"
              >
                <span className="text-3xl mr-4">{entry.mood.emoji}</span>
                <div className="flex-1">
                  <div className="flex justify-between items-start mb-2">
                    <span className="text-white">{entry.mood.label}</span>
                    <span className="text-calm-200 text-sm">
                      {formatDate(entry.timestamp)}
                    </span>
                  </div>
                  {entry.note && (
                    <p className="text-calm-100 text-sm">{entry.note}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default MoodTracker;