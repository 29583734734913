import React from 'react';
import { Link } from 'react-router-dom';
import BreathingCircle from '../components/BreathingCircle';
import BreathingCorners from '../components/BreathingCorners';

function Home() {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Background Video */}
      <video autoPlay muted loop playsInline className="fixed inset-0 w-full h-full object-cover opacity-30">
        <source src="/ocean-waves.mp4" type="video/mp4" />
      </video>

      {/* Breathing Corners */}
      <BreathingCorners />

      {/* Main Content */}
      <main className="relative z-10 flex-grow flex flex-col items-center justify-center px-4">
        <div className="text-center mb-8">
          <h1 className="text-4xl md:text-6xl font-light text-white mb-4">Find Your Inner Peace</h1>
          <p className="text-lg text-calm-100 opacity-90">Take a moment to breathe and center yourself</p>
        </div>

        <BreathingCircle />

        {/* Quick Access Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-16 max-w-6xl mx-auto">
          <Link to="/meditation" className="group">
            <div className="bg-white/10 backdrop-blur-md p-6 rounded-lg hover:bg-white/20 transition-all">
              <h3 className="text-xl font-semibold text-white mb-2">Guided Meditations</h3>
              <p className="text-calm-100">Explore our collection of guided meditations for inner peace</p>
            </div>
          </Link>
          <Link to="/tools" className="group">
            <div className="bg-white/10 backdrop-blur-md p-6 rounded-lg hover:bg-white/20 transition-all">
              <h3 className="text-xl font-semibold text-white mb-2">Wellness Tools</h3>
              <p className="text-calm-100">Discover tools for stress relief and emotional balance</p>
            </div>
          </Link>
          <Link to="/resources" className="group">
            <div className="bg-white/10 backdrop-blur-md p-6 rounded-lg hover:bg-white/20 transition-all">
              <h3 className="text-xl font-semibold text-white mb-2">Free Resources</h3>
              <p className="text-calm-100">Download guides, meditations, and tools for your journey</p>
            </div>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Home;