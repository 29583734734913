import React from 'react';

function BreathingCorners() {
  return (
    <>
      <div className="corner-overlay corner-top-left" />
      <div className="corner-overlay corner-top-right" />
      <div className="corner-overlay corner-bottom-left" />
      <div className="corner-overlay corner-bottom-right" />
    </>
  );
}

export default BreathingCorners;