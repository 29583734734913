import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';

function MeditationSession() {
  const { id } = useParams();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(null);
  const progressInterval = useRef(null);

  const sessions = {
    'deep-relaxation': {
      title: "Deep Relaxation Journey",
      duration: "20:00",
      description: "A guided meditation for complete relaxation and stress relief.",
      audioSrc: "/meditations/deep-relaxation.mp3",
      backgroundImage: "/images/meditation-bg-1.jpg",
      guide: "Sarah Mitchell",
      level: "All Levels"
    },
    'mindful-breathing': {
      title: "Mindful Breathing",
      duration: "10:00",
      description: "Learn the basics of mindful breathing techniques.",
      audioSrc: "/meditations/mindful-breathing.mp3",
      backgroundImage: "/images/meditation-bg-2.jpg",
      guide: "David Chen",
      level: "Beginner"
    },
    'body-scan': {
      title: "Body Scan",
      duration: "15:00",
      description: "Progressive relaxation through body awareness.",
      audioSrc: "/meditations/body-scan.mp3",
      backgroundImage: "/images/meditation-bg-3.jpg",
      guide: "Emma Watson",
      level: "Intermediate"
    }
  };

  const session = sessions[id];

  useEffect(() => {
    if (!session) return;

    // Set audio to loop
    if (audioRef.current) {
      audioRef.current.loop = true;
    }

    // Clean up on unmount
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, [session]);

  const togglePlayPause = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
      clearInterval(progressInterval.current);
    } else {
      audioRef.current.play();
      progressInterval.current = setInterval(() => {
        const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
        setProgress(progress);
      }, 1000);
    }
    setIsPlaying(!isPlaying);
  };

  if (!session) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <h1 className="text-4xl font-light text-white mb-4">Session Not Found</h1>
          <Link to="/meditation" className="text-calm-300 hover:text-white transition-colors">
            ← Back to Meditations
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      {/* Background Image */}
      <div 
        className="fixed inset-0 z-0"
        style={{
          backgroundImage: `url(${session.backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.3
        }}
      />

      <div className="relative z-10 container mx-auto px-4 py-8">
        <Link to="/meditation" className="text-calm-300 hover:text-white transition-colors inline-block mb-8">
          ← Back to Meditations
        </Link>

        <div className="bg-white/10 backdrop-blur-md rounded-lg p-8">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl font-light text-white mb-4">{session.title}</h1>
            <p className="text-calm-100 mb-6">{session.description}</p>
            
            <div className="flex justify-center items-center space-x-4 mb-8 text-sm text-calm-200">
              <span>Guide: {session.guide}</span>
              <span>•</span>
              <span>Duration: {session.duration}</span>
              <span>•</span>
              <span>Level: {session.level}</span>
            </div>

            {/* Audio Player */}
            <div className="space-y-4">
              <div className="w-full bg-white/10 rounded-full h-2">
                <div 
                  className="bg-calm-500 h-2 rounded-full transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>

              <button
                onClick={togglePlayPause}
                className="bg-calm-600 hover:bg-calm-500 text-white w-16 h-16 rounded-full flex items-center justify-center transition-colors"
              >
                {isPlaying ? (
                  <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6" />
                  </svg>
                ) : (
                  <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  </svg>
                )}
              </button>

              <audio
                ref={audioRef}
                src={session.audioSrc}
                loop
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeditationSession;