export const blogPosts = [
  // 保留现有的文章...
  {
    id: 'digital-mindfulness',
    slug: 'digital-mindfulness-finding-balance',
    title: "Digital Mindfulness: Finding Balance in a Connected World",
    excerpt: "Learn how to maintain mindfulness and well-being in our digital age.",
    content: `
      <p>In today's hyperconnected world, finding digital balance is crucial for mental well-being. Let's explore how to practice mindfulness in our digital lives.</p>

      <h2>The Digital Dilemma</h2>
      <p>While technology enriches our lives, constant connectivity can lead to:</p>
      <ul>
        <li>Information overload</li>
        <li>Digital anxiety</li>
        <li>Decreased attention span</li>
        <li>Poor sleep quality</li>
        <li>Reduced real-world connections</li>
      </ul>

      <h2>Digital Mindfulness Practices</h2>
      <ol>
        <li>Set intentional screen-free times</li>
        <li>Practice mindful social media use</li>
        <li>Create digital boundaries</li>
        <li>Use mindfulness apps purposefully</li>
        <li>Regular digital detox periods</li>
      </ol>

      <h2>Creating Healthy Digital Habits</h2>
      <p>Implement these strategies for better digital well-being:</p>
      <ul>
        <li>Morning routine without devices</li>
        <li>Mindful email checking</li>
        <li>Regular screen breaks</li>
        <li>Evening wind-down routine</li>
        <li>Mindful notification management</li>
      </ul>
    `,
    author: "Alex Chen",
    role: "Digital Wellness Expert",
    date: "2024-02-11",
    readTime: "6 min read",
    category: "Modern Life",
    tags: ["digital-wellness", "mindfulness", "technology", "mental-health"],
    image: "/blog/digital-mindfulness.jpg",
    metaDescription: "Discover how to maintain mindfulness and well-being in the digital age. Learn practical strategies for digital balance.",
    keywords: "digital mindfulness, technology balance, digital wellness, screen time"
  },
  {
    id: 'mindful-relationships',
    slug: 'building-mindful-relationships',
    title: "Building Mindful Relationships: The Art of Conscious Connection",
    excerpt: "Discover how mindfulness can transform your relationships and deepen your connections.",
    content: `
      <p>Mindful relationships are built on presence, understanding, and compassion. Learn how to apply mindfulness principles to enhance your connections with others.</p>

      <h2>The Foundation of Mindful Relationships</h2>
      <p>Key elements include:</p>
      <ul>
        <li>Present moment awareness</li>
        <li>Active listening</li>
        <li>Emotional awareness</li>
        <li>Non-judgmental acceptance</li>
        <li>Compassionate communication</li>
      </ul>

      <h2>Practical Techniques</h2>
      <ol>
        <li>Practice mindful listening</li>
        <li>Develop emotional awareness</li>
        <li>Cultivate empathy</li>
        <li>Express gratitude regularly</li>
        <li>Create mindful moments together</li>
      </ol>

      <h2>Overcoming Common Challenges</h2>
      <p>Address relationship challenges mindfully:</p>
      <ul>
        <li>Managing conflict with awareness</li>
        <li>Practicing patience</li>
        <li>Setting healthy boundaries</li>
        <li>Maintaining individual growth</li>
        <li>Nurturing shared values</li>
      </ul>
    `,
    author: "Dr. Sarah Johnson",
    role: "Relationship Counselor",
    date: "2024-02-10",
    readTime: "7 min read",
    category: "Relationships",
    tags: ["relationships", "mindfulness", "communication", "personal-growth"],
    image: "/blog/mindful-relationships.jpg",
    metaDescription: "Learn how to build stronger, more mindful relationships through conscious connection and communication.",
    keywords: "mindful relationships, conscious connection, relationship mindfulness, communication"
  }
  // 可以继续添加更多文章...
];