import React from 'react';

function BreathingCircle() {
  return (
    <div className="breathing-container">
      <div className="breathing-circle"></div>
      <div className="breathing-text inhale-text">INHALE</div>
      <div className="breathing-text exhale-text">EXHALE</div>
    </div>
  );
}

export default BreathingCircle;