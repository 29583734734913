import React, { useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { blogPosts } from '../data/blogPosts';

function BlogPost() {
  const { slug } = useParams();
  const navigate = useNavigate();
  
  const post = blogPosts.find(post => post.slug === slug);

  useEffect(() => {
    if (!post) {
      navigate('/blog', { replace: true });
    }
  }, [post, navigate]);

  if (!post) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`${post.title} - HushBalance Blog`}</title>
        <meta name="description" content={post.metaDescription} />
        <meta name="keywords" content={post.keywords} />
        <link rel="canonical" href={`https://hushbalance.com/blog/${post.slug}`} />
        
        {/* Schema.org Article Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": post.title,
            "description": post.metaDescription,
            "image": `https://hushbalance.com${post.image}`,
            "author": {
              "@type": "Person",
              "name": post.author
            },
            "datePublished": post.date,
            "publisher": {
              "@type": "Organization",
              "name": "HushBalance",
              "logo": {
                "@type": "ImageObject",
                "url": "https://hushbalance.com/logo.png"
              }
            }
          })}
        </script>

        {/* Open Graph Tags */}
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.metaDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://hushbalance.com/blog/${post.slug}`} />
        <meta property="og:image" content={`https://hushbalance.com${post.image}`} />
        <meta property="article:published_time" content={post.date} />
        <meta property="article:author" content={post.author} />
        {post.tags.map(tag => (
          <meta key={tag} property="article:tag" content={tag} />
        ))}
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.metaDescription} />
        <meta name="twitter:image" content={`https://hushbalance.com${post.image}`} />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <Link to="/blog" className="text-calm-300 hover:text-white transition-colors inline-block mb-8">
          ← Back to Blog
        </Link>
        
        <article className="bg-white/10 backdrop-blur-md rounded-lg p-8">
          <header className="mb-8">
            <img 
              src={post.image} 
              alt={post.title} 
              className="w-full h-[500px] object-cover rounded-lg mb-8"
            />
            
            <h1 className="text-4xl font-light text-white mb-4">{post.title}</h1>
            
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-white">{post.author}</p>
                <p className="text-calm-200 text-sm">{post.role}</p>
              </div>
              <time dateTime={post.date} className="text-calm-200">
                {new Date(post.date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </time>
            </div>

            <div className="flex flex-wrap gap-2">
              {post.tags.map(tag => (
                <span 
                  key={tag}
                  className="bg-white/5 text-calm-200 px-3 py-1 rounded-full text-sm"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </header>

          <div 
            className="prose prose-invert prose-lg max-w-none text-calm-100"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </article>
      </div>
    </>
  );
}

export default BlogPost;