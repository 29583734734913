import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Products() {
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = [
    { id: 'all', name: 'All' },
    { id: 'meditation', name: 'Meditation' },
    { id: 'aromatherapy', name: 'Aromatherapy' },
    { id: 'wellness', name: 'Wellness' },
    { id: 'decor', name: 'Decor' },
    { id: 'digital', name: 'Digital Products' }
  ];

  const products = [
    // Physical Products
    {
      id: 'handmade-beeswax-candle',
      name: "Handmade Beeswax Aromatherapy Candle",
      category: "aromatherapy",
      price: "$24.99",
      description: "Natural beeswax candle infused with essential oils for a calming atmosphere.",
      image: "/products/candle.jpg",
      features: ["Natural Materials", "Handcrafted", "Long-lasting", "Calming Scent"],
      type: "physical"
    },
    // ... other physical products ...

    // Digital Products
    {
      id: 'mindfulness-beginners-guide',
      name: "Mindfulness for Beginners Guide",
      category: "digital",
      price: "$12.99",
      description: "Comprehensive PDF guide to start your mindfulness journey.",
      image: "/products/mindfulness-guide.jpg",
      features: ["Instant Download", "100+ Pages", "Practical Exercises", "Beginner Friendly"],
      type: "digital"
    },
    {
      id: 'meditation-basics-course',
      name: "Meditation Basics Video Course",
      category: "digital",
      price: "$29.99",
      description: "Complete video course teaching meditation fundamentals.",
      image: "/products/meditation-course.jpg",
      features: ["10 Video Lessons", "Lifetime Access", "Practice Sheets", "Mobile Friendly"],
      type: "digital"
    },
    {
      id: 'stress-management-ebook',
      name: "Stress Management eBook Bundle",
      category: "digital",
      price: "$19.99",
      description: "Collection of eBooks focusing on stress reduction techniques.",
      image: "/products/stress-ebook.jpg",
      features: ["3 eBooks", "Audio Companion", "Worksheets", "Scientific Approach"],
      type: "digital"
    },
    {
      id: 'mindful-living-workshop',
      name: "Mindful Living Workshop",
      category: "digital",
      price: "$49.99",
      description: "Recorded workshop sessions with mindfulness experts.",
      image: "/products/workshop.jpg",
      features: ["6 Hours Content", "Expert Sessions", "Q&A Recordings", "Resource Pack"],
      type: "digital"
    }
  ];

  const filteredProducts = selectedCategory === 'all' 
    ? products 
    : products.filter(product => product.category === selectedCategory);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-light text-white mb-2">Mindful Shop</h1>
        <p className="text-calm-100">Curated products for your mindfulness journey</p>
      </div>

      {/* Categories */}
      <div className="flex flex-wrap justify-center gap-4 mb-12">
        {categories.map((category) => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category.id)}
            className={`px-6 py-2 rounded-full transition-colors ${
              selectedCategory === category.id
                ? 'bg-calm-600 text-white'
                : 'bg-white/10 text-calm-100 hover:bg-white/20'
            }`}
          >
            {category.name}
          </button>
        ))}
      </div>

      {/* Products Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredProducts.map((product) => (
          <div key={product.id} className="group">
            <div className="bg-white/10 backdrop-blur-md rounded-lg overflow-hidden transition-transform duration-300 hover:-translate-y-2">
              {/* Product Image */}
              <div className="relative h-64 overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent z-10" />
                {product.type === 'digital' && (
                  <div className="absolute top-4 right-4 z-20 bg-calm-600 text-white px-3 py-1 rounded-full text-sm">
                    Digital Product
                  </div>
                )}
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-110"
                />
              </div>

              {/* Product Info */}
              <div className="p-6">
                <h3 className="text-xl font-semibold text-white mb-2">{product.name}</h3>
                <p className="text-calm-100 mb-4">{product.description}</p>
                
                {/* Features */}
                <div className="flex flex-wrap gap-2 mb-4">
                  {product.features.map((feature, index) => (
                    <span
                      key={index}
                      className="text-xs bg-white/5 text-calm-200 px-2 py-1 rounded-full"
                    >
                      {feature}
                    </span>
                  ))}
                </div>

                {/* Price and Action */}
                <div className="flex items-center justify-between">
                  <span className="text-2xl font-light text-white">{product.price}</span>
                  <Link 
                    to={`/products/${product.id}`}
                    className="bg-calm-600 hover:bg-calm-500 text-white px-4 py-2 rounded-lg transition-colors"
                  >
                    {product.type === 'digital' ? 'Learn More' : 'View Details'}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;