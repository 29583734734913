import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import DefaultMeta from './components/SEO/DefaultMeta';
import './style.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HelmetProvider>
      <DefaultMeta />
      <App />
    </HelmetProvider>
  </React.StrictMode>
);