import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Meditation from './pages/Meditation';
import Tools from './pages/Tools';
import Wiki from './pages/Wiki';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Forum from './pages/Forum';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Products from './pages/Products';
import ProductDetail from './pages/ProductDetail';
import MeditationSession from './pages/MeditationSession';
import Resources from './pages/Resources';
import ResourceDetail from './pages/ResourceDetail';
import Analytics from './components/Analytics';
import { pageview } from './utils/analytics';

// Analytics wrapper component
function AnalyticsWrapper() {
  const location = useLocation();

  useEffect(() => {
    pageview(location.pathname + location.search);
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <Analytics />
      <AnalyticsWrapper />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="meditation" element={<Meditation />} />
          <Route path="meditation/:id" element={<MeditationSession />} />
          <Route path="tools" element={<Tools />} />
          <Route path="wiki" element={<Wiki />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:slug" element={<BlogPost />} />
          <Route path="forum" element={<Forum />} />
          <Route path="products" element={<Products />} />
          <Route path="products/:id" element={<ProductDetail />} />
          <Route path="resources" element={<Resources />} />
          <Route path="resources/:slug" element={<ResourceDetail />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;