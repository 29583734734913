import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { blogPosts } from '../data/blogPosts';

function Blog() {
  const [visiblePosts, setVisiblePosts] = useState(6);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  
  const categories = useMemo(() => {
    const cats = new Set(blogPosts.map(post => post.category));
    return ['all', ...Array.from(cats)];
  }, []);

  const filteredPosts = useMemo(() => {
    let filtered = blogPosts;
    
    if (selectedCategory !== 'all') {
      filtered = filtered.filter(post => post.category === selectedCategory);
    }
    
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(post => 
        post.title.toLowerCase().includes(query) ||
        post.excerpt.toLowerCase().includes(query) ||
        post.tags.some(tag => tag.toLowerCase().includes(query))
      );
    }
    
    return filtered;
  }, [selectedCategory, searchQuery]);

  const displayedPosts = filteredPosts.slice(0, visiblePosts);

  return (
    <>
      <Helmet>
        <title>Mindfulness Blog - HushBalance</title>
        <meta name="description" content="Explore mindfulness articles, meditation guides, and wellness tips to enhance your journey to inner peace." />
        <meta name="keywords" content="mindfulness blog, meditation articles, wellness tips, mental health, inner peace" />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-light text-white mb-8">Mindfulness Blog</h1>

        {/* Search and Filter */}
        <div className="mb-8 space-y-4">
          <input
            type="search"
            placeholder="Search articles..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full md:w-96 bg-white/10 text-white placeholder-calm-200 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-calm-400"
          />
          
          <div className="flex flex-wrap gap-2">
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full transition-colors ${
                  selectedCategory === category
                    ? 'bg-calm-600 text-white'
                    : 'bg-white/10 text-calm-100 hover:bg-white/20'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Featured Post */}
        {selectedCategory === 'all' && !searchQuery && (
          <Link to={`/blog/${displayedPosts[0]?.slug}`} className="block mb-8">
            <div className="bg-white/10 backdrop-blur-md rounded-lg p-8 hover:bg-white/20 transition-all">
              <span className="text-calm-300 text-sm">{displayedPosts[0]?.category}</span>
              <h2 className="text-2xl font-semibold text-white mt-2 mb-4">{displayedPosts[0]?.title}</h2>
              <p className="text-calm-100 mb-4">{displayedPosts[0]?.excerpt}</p>
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-white">{displayedPosts[0]?.author}</p>
                  <p className="text-calm-200 text-sm">{displayedPosts[0]?.role} • {displayedPosts[0]?.readTime}</p>
                </div>
                <time dateTime={displayedPosts[0]?.date} className="text-calm-200 text-sm">
                  {new Date(displayedPosts[0]?.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </time>
              </div>
            </div>
          </Link>
        )}

        {/* Blog Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {displayedPosts.slice(selectedCategory === 'all' && !searchQuery ? 1 : 0).map((post) => (
            <article key={post.id} className="bg-white/10 backdrop-blur-md rounded-lg overflow-hidden hover:bg-white/20 transition-all">
              <Link to={`/blog/${post.slug}`}>
                <img 
                  src={post.image} 
                  alt={post.title}
                  className="w-full h-48 object-cover"
                  loading="lazy"
                />
                <div className="p-6">
                  <div className="flex items-center gap-2 mb-2">
                    <span className="text-calm-300 text-sm">{post.category}</span>
                    <span className="text-calm-200">•</span>
                    <span className="text-calm-200 text-sm">{post.readTime}</span>
                  </div>
                  <h3 className="text-xl font-semibold text-white mb-2">{post.title}</h3>
                  <p className="text-calm-100 mb-4">{post.excerpt}</p>
                  <div className="flex items-center justify-between mt-4">
                    <div className="text-sm">
                      <p className="text-white">{post.author}</p>
                      <p className="text-calm-200">{post.role}</p>
                    </div>
                    <time dateTime={post.date} className="text-calm-200 text-sm">
                      {new Date(post.date).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric'
                      })}
                    </time>
                  </div>
                </div>
              </Link>
            </article>
          ))}
        </div>

        {/* Load More Button */}
        {visiblePosts < filteredPosts.length && (
          <div className="text-center mt-8">
            <button 
              onClick={() => setVisiblePosts(prev => prev + 6)}
              className="bg-calm-600 hover:bg-calm-500 text-white px-6 py-2 rounded-lg transition-colors"
            >
              Load More Posts
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default Blog;