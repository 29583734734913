export const resources = [
  {
    id: 'mindfulness-beginners-guide',
    slug: 'mindfulness-beginners-guide',
    title: 'Mindfulness for Beginners Guide',
    description: 'A comprehensive guide to start your mindfulness journey.',
    type: 'ebook',
    format: 'PDF',
    size: '2.4 MB',
    coverImage: '/resources/mindfulness-guide-cover.jpg',
    downloadUrl: '/downloads/mindfulness-beginners-guide.pdf',
    category: 'Guides',
    tags: ['mindfulness', 'meditation', 'beginners'],
    author: 'Sarah Mitchell',
    publishDate: '2024-02-01',
    metaDescription: 'Download our comprehensive mindfulness guide for beginners. Learn meditation techniques and mindful living practices.',
    keywords: 'mindfulness guide, meditation guide, beginners mindfulness, free mindfulness resources'
  },
  {
    id: 'deep-relaxation-meditation',
    slug: 'deep-relaxation-meditation-audio',
    title: 'Deep Relaxation Meditation',
    description: 'A calming guided meditation for deep relaxation and stress relief.',
    type: 'audio',
    format: 'MP3',
    size: '18.2 MB',
    coverImage: '/resources/meditation-audio-cover.jpg',
    downloadUrl: '/downloads/deep-relaxation-meditation.mp3',
    duration: '20 minutes',
    category: 'Meditation Audio',
    tags: ['meditation', 'relaxation', 'stress-relief'],
    author: 'David Chen',
    publishDate: '2024-02-05',
    metaDescription: 'Download our guided deep relaxation meditation audio. Perfect for stress relief and peaceful sleep.',
    keywords: 'meditation audio, relaxation guide, stress relief meditation, sleep meditation'
  },
  {
    id: 'mindful-living-workbook',
    slug: 'mindful-living-workbook',
    title: 'Mindful Living Workbook',
    description: 'Interactive workbook with exercises and journaling prompts for mindful living.',
    type: 'workbook',
    format: 'PDF',
    size: '3.8 MB',
    coverImage: '/resources/workbook-cover.jpg',
    downloadUrl: '/downloads/mindful-living-workbook.pdf',
    category: 'Workbooks',
    tags: ['workbook', 'exercises', 'journaling'],
    author: 'Emma Watson',
    publishDate: '2024-02-08',
    metaDescription: 'Download our mindful living workbook with practical exercises and journaling prompts for daily mindfulness practice.',
    keywords: 'mindfulness workbook, mindful exercises, journaling prompts, mindfulness practice'
  }
];