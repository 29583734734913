import React from 'react';
import { Helmet } from 'react-helmet-async';

function DefaultMeta() {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>HushBalance - Find Your Inner Peace Through Meditation</title>
      <meta name="title" content="HushBalance - Find Your Inner Peace Through Meditation" />
      <meta name="description" content="Discover inner peace through guided meditation, breathing exercises, and mindfulness practices. Join our community for a calmer, more balanced life." />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://hushbalance.com/" />
      <meta property="og:title" content="HushBalance - Your Journey to Inner Peace" />
      <meta property="og:description" content="Transform your life through meditation and mindfulness practices. Start your journey to inner peace today." />
      <meta property="og:image" content="/og-image.jpg" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://hushbalance.com/" />
      <meta property="twitter:title" content="HushBalance - Find Your Inner Peace" />
      <meta property="twitter:description" content="Join our mindfulness community and discover the power of meditation." />
      <meta property="twitter:image" content="/twitter-image.jpg" />

      {/* Additional SEO Meta Tags */}
      <meta name="keywords" content="meditation, mindfulness, breathing exercises, inner peace, wellness, mental health, relaxation, stress relief" />
      <meta name="author" content="HushBalance" />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      
      {/* Favicon */}
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="manifest" href="/site.webmanifest" />

      {/* Canonical URL */}
      <link rel="canonical" href="https://hushbalance.com/" />
    </Helmet>
  );
}

export default DefaultMeta;